/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActivateUserRequest } from '../models/ActivateUserRequest';
import type { ActivateUserResponse } from '../models/ActivateUserResponse';
import type { BookEventRequest } from '../models/BookEventRequest';
import type { BookEventResponse } from '../models/BookEventResponse';
import type { CallMeBackRequest } from '../models/CallMeBackRequest';
import type { CallMeBackResponse } from '../models/CallMeBackResponse';
import type { CheckoutShopifyRequest } from '../models/CheckoutShopifyRequest';
import type { CheckoutShopifyResponse } from '../models/CheckoutShopifyResponse';
import type { EnrollInstructorToEventRequest } from '../models/EnrollInstructorToEventRequest';
import type { EnrollInstructorToEventResponse } from '../models/EnrollInstructorToEventResponse';
import type { GetSessionHistoryRequest } from '../models/GetSessionHistoryRequest';
import type { GetSessionHistoryResponse } from '../models/GetSessionHistoryResponse';
import type { InformationRequestRequest } from '../models/InformationRequestRequest';
import type { LogErrorsRequest } from '../models/LogErrorsRequest';
import type { LogErrorsResponse } from '../models/LogErrorsResponse';
import type { PostDownloadBrochureRequest } from '../models/PostDownloadBrochureRequest';
import type { SignupRequest } from '../models/SignupRequest';
import type { SignupResponse } from '../models/SignupResponse';
import type { SocialSignupRequest } from '../models/SocialSignupRequest';
import type { SubmitAdmissionRequestRequest } from '../models/SubmitAdmissionRequestRequest';
import type { SubmitAdmissionRequestResponse } from '../models/SubmitAdmissionRequestResponse';
import type { SubmitDownloadBrochureResponse } from '../models/SubmitDownloadBrochureResponse';
import type { SubmitInformationRequestResponse } from '../models/SubmitInformationRequestResponse';
import type { SubmitOrientationRequestRequest } from '../models/SubmitOrientationRequestRequest';
import type { SubmitOrientationRequestResponse } from '../models/SubmitOrientationRequestResponse';
import type { SubmitPartialAdmissionRequestRequest } from '../models/SubmitPartialAdmissionRequestRequest';
import type { SubmitPartialAdmissionRequestResponse } from '../models/SubmitPartialAdmissionRequestResponse';
import type { SubmitScholarshipRequestRequest } from '../models/SubmitScholarshipRequestRequest';
import type { SubmitScholarshipRequestResponse } from '../models/SubmitScholarshipRequestResponse';
import type { UpdateDownloadBrochureRequest } from '../models/UpdateDownloadBrochureRequest';
import type { UpdateDownloadBrochureResponse } from '../models/UpdateDownloadBrochureResponse';
import type { UpdateUserProfileRequest } from '../models/UpdateUserProfileRequest';
import type { UpdateUserProfileResponse } from '../models/UpdateUserProfileResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param uid
     * @param requestBody
     * @returns UpdateDownloadBrochureResponse 200 response
     * @throws ApiError
     */
    public updateDownloadBrochure(
        uid: string,
        requestBody: UpdateDownloadBrochureRequest,
    ): CancelablePromise<UpdateDownloadBrochureResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/brochure/download/{uid}',
            path: {
                'uid': uid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                404: `404 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @param uid
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsBrochureDownload(
        uid: string,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/brochure/download/{uid}',
            path: {
                'uid': uid,
            },
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitAdmissionRequestResponse 200 response
     * @throws ApiError
     */
    public submitAdmissionRequest(
        requestBody: SubmitAdmissionRequestRequest,
    ): CancelablePromise<SubmitAdmissionRequestResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/admissionRequest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsAdmissionRequest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/admissionRequest',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @throws ApiError
     */
    public version(): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/version',
        });
    }
    /**
     * @param requestBody
     * @returns CallMeBackResponse 200 response
     * @throws ApiError
     */
    public callMeBack(
        requestBody: CallMeBackRequest,
    ): CancelablePromise<CallMeBackResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/callMeBack',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsCallMeBack(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/callMeBack',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SignupResponse 200 response
     * @throws ApiError
     */
    public socialSignup(
        requestBody: SocialSignupRequest,
    ): CancelablePromise<SignupResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/socialSignup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsUserSocialSignup(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/socialSignup',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns LogErrorsResponse 200 response
     * @throws ApiError
     */
    public logErrors(
        requestBody: LogErrorsRequest,
    ): CancelablePromise<LogErrorsResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/logging/errors',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsLoggingErrors(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/logging/errors',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param token
     * @throws ApiError
     */
    public streamBrochure(
        token: string,
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/brochure/stream',
            query: {
                'token': token,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsBrochureStream(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/brochure/stream',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SignupResponse 200 response
     * @throws ApiError
     */
    public signup(
        requestBody: SignupRequest,
    ): CancelablePromise<SignupResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/signup',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsUserSignup(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/signup',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitOrientationRequestResponse 200 response
     * @throws ApiError
     */
    public submitOrientationRequest(
        requestBody: SubmitOrientationRequestRequest,
    ): CancelablePromise<SubmitOrientationRequestResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/orientationRequest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsOrientationRequest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/orientationRequest',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitPartialAdmissionRequestResponse 200 response
     * @throws ApiError
     */
    public submitPartialAdmissionRequest(
        requestBody: SubmitPartialAdmissionRequestRequest,
    ): CancelablePromise<SubmitPartialAdmissionRequestResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/partialAdmissionRequest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsPartialAdmissionRequest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/partialAdmissionRequest',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns EnrollInstructorToEventResponse 200 response
     * @throws ApiError
     */
    public enrollInstructorToEvent(
        requestBody: EnrollInstructorToEventRequest,
    ): CancelablePromise<EnrollInstructorToEventResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/event/enrollInstructor',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsEventEnrollInstructor(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/event/enrollInstructor',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns BookEventResponse 200 response
     * @throws ApiError
     */
    public bookEvent(
        requestBody: BookEventRequest,
    ): CancelablePromise<BookEventResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/event/book',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsEventBook(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/event/book',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns UpdateUserProfileResponse 200 response
     * @throws ApiError
     */
    public updateUserProfile(
        requestBody: UpdateUserProfileRequest,
    ): CancelablePromise<UpdateUserProfileResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/update',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsUserUpdate(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/update',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitDownloadBrochureResponse 200 response
     * @throws ApiError
     */
    public submitDownloadBrochure(
        requestBody: PostDownloadBrochureRequest,
    ): CancelablePromise<SubmitDownloadBrochureResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/brochure/download',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsBrochureDownload1(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/brochure/download',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns GetSessionHistoryResponse 200 response
     * @throws ApiError
     */
    public getSessionHistory(
        requestBody: GetSessionHistoryRequest,
    ): CancelablePromise<GetSessionHistoryResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/history/session',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsHistorySession(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/history/session',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns CheckoutShopifyResponse 200 response
     * @throws ApiError
     */
    public checkoutShopify(
        requestBody: CheckoutShopifyRequest,
    ): CancelablePromise<CheckoutShopifyResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/checkoutShopify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsCheckoutShopify(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/checkoutShopify',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns ActivateUserResponse 200 response
     * @throws ApiError
     */
    public activateUser(
        requestBody: ActivateUserRequest,
    ): CancelablePromise<ActivateUserResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsUserActivate(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/user/activate',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitScholarshipRequestResponse 200 response
     * @throws ApiError
     */
    public submitScholarshipRequest(
        requestBody: SubmitScholarshipRequestRequest,
    ): CancelablePromise<SubmitScholarshipRequestResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/scholarshipRequest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsScholarshipRequest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/scholarshipRequest',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
    /**
     * @param requestBody
     * @returns SubmitInformationRequestResponse 200 response
     * @throws ApiError
     */
    public submitInformationRequest(
        requestBody: InformationRequestRequest,
    ): CancelablePromise<SubmitInformationRequestResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/informationRequest',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `400 response`,
                500: `500 response`,
            },
        });
    }
    /**
     * @returns string 200 response
     * @throws ApiError
     */
    public optionsInformationRequest(): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'OPTIONS',
            url: '/informationRequest',
            responseHeader: 'Access-Control-Allow-Origin',
        });
    }
}
