import { CancelablePromise, DefaultService } from './generated/cta-services';


export type CustomEventCallback<T = any> = (response: T) => void;

export type ExtendedDefaultService = {
    [K in keyof DefaultService]: DefaultService[K] extends (...args: infer A) => CancelablePromise<infer R>
        ? (...args: [...A, CustomEventCallback<R>?]) => CancelablePromise<R>
        : DefaultService[K];
};

/**
 * Creates a proxy for a given service that extends `DefaultService`, allowing methods
 * to accept an optional callback function. This callback will be invoked with the result 
 * of the `CancelablePromise` if provided, enabling custom handling of the response without 
 * altering the original method signatures.
 * 
 * The proxy wraps each method of the `defaultService` that returns a `CancelablePromise`.
 * If the last argument of the method is a function, it is treated as a callback and will be 
 * called with the resolved response of the `CancelablePromise`. Errors during promise resolution 
 * are caught and logged.
 * 
 * @template T - An extension of `ExtendedDefaultService`, representing the proxied service type.
 * @param {T} defaultService - The original service object to be proxied, which contains methods 
 * that return `CancelablePromise`.
 * @returns {T} - A proxied version of the input service, where methods can accept an additional 
 * callback parameter for handling the promise resolution.
 **/
function createProxiedService<T extends ExtendedDefaultService>(defaultService: T): T {
    return new Proxy(defaultService, {
        get(target, prop: string | symbol, receiver) {
            if (typeof target[prop as keyof T] === 'function') {
                const originalMethod = target[prop as keyof T] as (...args: any[]) => any;

                return function (this: T, ...args: any[]) {
                    const callback: CustomEventCallback | undefined =
                        typeof args[args.length - 1] === 'function' ? args.pop() : undefined;

                    const result = originalMethod.apply(this, args);

                    if (result instanceof CancelablePromise && callback) {
                        return result.then((response: any) => {
                            callback(response);
                            return response;
                        }).catch((error: any) => {
                            console.error(`Error in method ${String(prop)}:`, error);
                            throw error;
                        });
                    }

                    return result;
                }.bind(target);
            }

            return Reflect.get(target, prop, receiver);
        },
    }) as T;
}


export default createProxiedService