import { CtaServicesApiClient } from './generated/cta-services';
import { useOktaStore } from '$lib/utils/auth-store';
import { PUBLIC_CTA_SERVICES_API_KEY, PUBLIC_CTA_SERVICES_URL } from '$env/static/public';
import createProxiedService, { type ExtendedDefaultService } from './ExtendedCtaServiceProxy';

/**
 * Singleton class to get the CTA Services API client instance
 */
class CtaServicesApiClientSingleton {
    private static instance: ExtendedDefaultService | null = null;

    public static async getInstance(): Promise<ExtendedDefaultService> {
        const apiKey = PUBLIC_CTA_SERVICES_API_KEY ?? '';
        const apiUrl = PUBLIC_CTA_SERVICES_URL ?? '';

        if (!apiKey) {
            throw new Error('No API key found');
        }

        if (!apiUrl) {
            throw new Error('No API URL found');
        }

        const headers: any = {
            'x-api-key': apiKey
        };

        const authStore = useOktaStore();
        const accessToken = authStore.accessToken;

        if (accessToken) {
            headers['Authorization'] = 'Bearer ' + accessToken;
        } else {
            console.warn('No access token found');
        }

        if (!CtaServicesApiClientSingleton.instance) {
            const defaultService = new CtaServicesApiClient({
                HEADERS: headers,
                BASE: apiUrl
            }).default;

            CtaServicesApiClientSingleton.instance = createProxiedService(defaultService);
        }

        return CtaServicesApiClientSingleton.instance;
    }
}

export default CtaServicesApiClientSingleton;